import Product from './product';
import Q from 'q';

var CartItem = function(data){
  this.id = data.id;
  this.createdAt = data.created_at;
  this.primaryCartItemId = data.primary_cart_item_id;
  this.product = new Product(data.product);
  this.productPagePath = data.product.product_path;
  this.delayedConfirmed = this.delayedConfirmed || false;
  this.delayed = this.delayed || false;
  this.modifications = data.modifications;
  this.price = Math.floor(data.price);
  this.displayName = data.gift_card_detail ? data.gift_card_detail.cart_display_name : this.product.displayNameWithDuration;
  this.isStudenPurchase = this.product.is_student_purchase;
};

CartItem.prototype.discountForTier = function(tier){
  return this.product['tier' + tier + 'CrossSellDiscount'];
};

CartItem.prototype.discountedPrice = function(){
  return this.modifications.updated_price;
};

CartItem.prototype.finalPrice = function(){
  if ( this.isDiscounted() ) {
    return this.discountedPrice();
  } else {
    return this.price;
  };
};

CartItem.prototype.discountingMessage = function(){
  return this.modifications.savings_description;
};

CartItem.prototype.isPrimary = function(){
  return _.isNull(this.primaryCartItemId);
};

CartItem.prototype.isDiscounted = function(){
  return !_.isEmpty(this.modifications) && this.modifications.valid_coupon;
};

CartItem.prototype.savingsDelta = function(){
  return this.price - this.finalPrice();
};

CartItem.prototype.destroy = function(){
  return $.ajax({
    url: '/api/rc/cart_items/' + this.id,
    type:'delete',
    contentType:'application/json',
    data: JSON.stringify({
      cart_item: {
        removed: true
      }
    })
  });
}

CartItem.prototype.merge = function(other){
  this.constructor.call(this, other);
}

export default CartItem;
