import Config from 'config';
import api, { selectedQuestionBankUrl } from 'helpers/api';
import { Set } from 'immutable';

import { questionBankSelect, questionBankFetchSubjects } from 'actions/questionBanks';
import { notificationsFetch } from 'actions/notifications';
import {
  performanceQuestionsFetch, performancePeersFetch, performanceQuizzesFetch, performanceResponsesFetch
} from 'actions/performance';

export const SESSION_FETCHING = 'SESSION_FETCHING';
export const SESSION_FETCHED = 'SESSION_FETCHED';
export const SESSION_RESET = 'SESSION_RESET';
export const SESSION_RESET_OFFLINE = 'SESSION_RESET_OFFLINE';


export const sessionReset = (key) => {
  Config.clearLocalStorage();
  Config.clearSessionStorage();

  return {
    type: SESSION_RESET,
    payload: { key }
  };
};

export const sessionResetOffline = () => ({
  type: SESSION_RESET_OFFLINE
});

const sessionFetched = (response, fetchAssociations = true, forceQuestionBankId = null, isIframe = false, key, isLTI = false) => (dispatch, getState) => {
  const userId = parseInt(response.data.id);

  let selectedQuestionBankIdChanged = false;
  let selectedQuestionBankId = 0;
  if (response.data.relationships.last_selected_question_bank.data) {
    selectedQuestionBankId = parseInt(response.data.relationships.last_selected_question_bank.data.id);
  }

  // Determine which question banks are available
  const questionBanksJSON = response.included.filter(record => record.type === 'question_bank');
  const availableQuestionBankIds = questionBanksJSON.map(questionBank => parseInt(questionBank.id));


  // Allow overriding the selected question bank with an optional argument
  if (forceQuestionBankId && availableQuestionBankIds.includes(parseInt(forceQuestionBankId)) && selectedQuestionBankId !== parseInt(forceQuestionBankId)) {
    response.data.relationships.last_selected_question_bank = { data: { id: forceQuestionBankId } };
    selectedQuestionBankId = parseInt(forceQuestionBankId);
    selectedQuestionBankIdChanged = true;
  }

  // Short workaround for issues relating to ATI users whitelisted questions banks getting set to []
  if (forceQuestionBankId && !selectedQuestionBankIdChanged && !response.data.attributes.whitelisted_question_bank_ids.includes(forceQuestionBankId)) {
    selectedQuestionBankIdChanged = true;
  }

  // Ensure that the selected question bank is available (QBs may have become unpublished).
  // I am also implementing a server-side fix for this but leaving this here to keep
  // the app from crashing on the off chance that this happens again.
  if (!selectedQuestionBankId || !availableQuestionBankIds.includes(selectedQuestionBankId)) {
    response.data.relationships.last_selected_question_bank = { data: { id: '0' } };
    selectedQuestionBankId = 0;
  }

  // If we had persisted local data then we make sure it matches the active session before using it
  const persistedUserId = getState().session.get('current_user_id');
  if (persistedUserId && persistedUserId !== userId) {
    dispatch(sessionReset());
  }
  // Always fetch subjects since this was decoupled from session_fetch and question_bank_select
  if (selectedQuestionBankId) dispatch(questionBankFetchSubjects(selectedQuestionBankId, userId));

  if (fetchAssociations) {
    dispatch(notificationsFetch(userId));
  }

  if (selectedQuestionBankId && availableQuestionBankIds.includes(selectedQuestionBankId)) {
    if (!selectedQuestionBankIdChanged && fetchAssociations) {
      if (isLTI) {
        dispatch(performanceQuizzesFetch(userId));
        dispatch(performanceResponsesFetch(userId));
        dispatch(performanceQuestionsFetch(selectedQuestionBankId, userId));
        dispatch(performancePeersFetch(selectedQuestionBankId, userId));
      } else {
        dispatch(performanceQuestionsFetch(selectedQuestionBankId, userId));
      }
    }

    if (selectedQuestionBankIdChanged) {
      dispatch(questionBankSelect(selectedQuestionBankId, userId, fetchAssociations));
    }
  }

  // Make sure that the current user has an active & verified admin or instructor membership for the selected organization
  let selectedOrganizationId = parseInt(response.data.relationships.last_selected_organization?.data?.id);
  const staffOrganizationIds = new Set(response.included
    .filter(o => o.type === 'organizational_membership'
      && o.relationships.user.data.id === response.data.id
      && !o.attributes.deactivated
      && o.attributes.verified
      && ['admin', 'instructor', 'super admin'].includes(o.attributes.role))
    .map(o => parseInt(o.relationships.organization.data.id))).toArray();

  if (!selectedOrganizationId || (staffOrganizationIds.length > 0 && !staffOrganizationIds.includes(selectedOrganizationId))) {
    selectedOrganizationId = parseInt(staffOrganizationIds[0]);
  }

  dispatch({
    type: SESSION_FETCHED,
    payload: {
      key,
      isIframe,
      selectedOrganizationId,
      ...response
    }
  });
};

const getSessionLogoutKey = () => 'session-logout';
export const sessionLogout = ev => (dispatch, getState) => {
  if (ev) ev.preventDefault();
  const key = getSessionLogoutKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);


  // sessionLogout will always resolve true
  const promise = api.delete('sessions/sign_out')
    .then((res) => {
      dispatch(sessionReset(key));
      return true;
    });

  dispatch({ type: SESSION_FETCHING, payload: { key, promise } });
  return promise;
};
sessionLogout.getKey = getSessionLogoutKey;

const getSessionLoginKey = () => 'session-login';
export const sessionLogin = (email, password, deviceId, fetchAssociations = true) => (dispatch, getState) => {
  const key = getSessionLoginKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post('sessions/sign_in', {
    user: {
      email,
      password,
      device_id: deviceId
    }
  })
    .then((response) => {
      dispatch(sessionFetched(response, fetchAssociations, null, false, key));
      return response;
    })
    .catch((error) => {
      dispatch(sessionReset(key));
      throw error;
    });

  dispatch({ type: SESSION_FETCHING, payload: { key, promise } });
  return promise;
};
sessionLogin.getKey = getSessionLoginKey;

const getSessionAtiLoginKey = () => 'session-ati-login';
export const sessionAtiLogin = (email, password, deviceId, fetchAssociations = true) => (dispatch, getState) => {
  const key = getSessionAtiLoginKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post('ati_sessions/sign_in', {
    user: {
      email,
      password,
      device_id: deviceId
    }
  })
    .then((response) => {
      dispatch(sessionFetched(response, fetchAssociations, null, false, key));
      return response;
    })
    .catch((error) => {
      dispatch(sessionReset(key));
      throw error;
    });

  dispatch({ type: SESSION_FETCHING, payload: { key, promise } });
  return promise;
};
sessionAtiLogin.getKey = getSessionAtiLoginKey;

const getSessionLoginLTIKey = () => 'session-login-lti';
export const sessionLoginLTI = (userID, assignmentID, fetchAssociations = true) => (dispatch, getState) => {
  const key = getSessionLoginLTIKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post('sessions/lti_sign_in', {
    user: {
      id: userID
    },
    assignment: {
      id: assignmentID
    }
  })
    .then((response) => {
      dispatch(sessionFetched(response, fetchAssociations, null, false, key, true));
      return response;
    })
    .catch((error) => {
      dispatch(sessionReset(key));
      throw error;
    });

  dispatch({ type: SESSION_FETCHING, payload: { key, promise } });
  return promise;
};
sessionLoginLTI.getKey = getSessionLoginLTIKey;

const getSessionCheckKey = () => 'session-check';
export const sessionCheck = (fetchAssociations = true, forceQuestionBankId = null, isIframe, impersonateId) => (dispatch, getState) => {
  const key = getSessionCheckKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);


  const promise = api.get(`sessions/check?is_iframe=${isIframe}${impersonateId ? `&impersonate=${impersonateId}` : ''}`)
    .then((response) => {
      if (response.data) {
        dispatch(sessionFetched(response, fetchAssociations, forceQuestionBankId, isIframe, key));
        return response;
      }
      dispatch(sessionReset(key));
      return false;
    })
    .catch((error) => {
      if (Config.browser) dispatch(sessionReset(key));
      if (!error.isNetworkError) {
        return false;
      }
      if (error.response && error.response.status === 404) return false;
      throw error;
    });

  dispatch({ type: SESSION_FETCHING, payload: { key, promise } });
  return promise;
};
sessionCheck.getKey = getSessionCheckKey;

export const SESSION_SET_SEARCH_ID = 'SESSION_SET_SEARCH_ID';
export const SESSION_SEARCH_ID_FETCHING = 'SESSION_SEARCH_ID_FETCHING';
const sessionSetSearchKey = () => 'session-set-search-id';
export const sessionSetSearchId = () => (dispatch, getState) => {
  const key = sessionSetSearchKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.get(`${selectedQuestionBankUrl(getState())}/search_init`)
    .then(response => dispatch({
      type: SESSION_SET_SEARCH_ID,
      payload: {
        key,
        searchId: response.data.search_id
      }
    }));

  dispatch({
    type: SESSION_SEARCH_ID_FETCHING,
    payload: { key, promise }
  });

  return promise;
};
sessionSetSearchId.getKey = sessionSetSearchKey;

const getSessionRegisterKey = () => 'session-register';
export const sessionRegister = (firstName, lastName, email, password, deviceId, fetchAssociations = true, forceQuestionBankId = null, isIframe) => (dispatch, getState) => {
  const key = getSessionRegisterKey();

  const { loading } = getState();
  if (loading.has(key)) return loading.get(key);

  const promise = api.post('/register', {
    user: {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      device_id: deviceId
    }
  })
    .then((response) => {
      dispatch(sessionFetched(response, fetchAssociations, forceQuestionBankId, isIframe, key));
      return response;
    })
    .catch((error) => {
      dispatch(sessionReset(key));
      throw error;
    });

  dispatch({ type: SESSION_FETCHING, payload: { key, promise } });
  return promise;
};
sessionRegister.getKey = getSessionRegisterKey;


export const SESSION_SET_CAN_SEE_CORRECT_ANSWERS = 'SESSION_SET_CAN_SEE_CORRECT_ANSWERS';
export const sessionSetCanSeeCorrectAnswers = enabled => (dispatch, getState) => {
  const { users, session } = getState();
  const currentUser = users.get(session.get('current_user_id'));

  dispatch({
    type: SESSION_SET_CAN_SEE_CORRECT_ANSWERS,
    payload: {
      enabled: enabled && currentUser && currentUser.get('internal_role') === 'admin'
    }
  });
};
