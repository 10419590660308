import React from "react";
import SvgContainer from './SvgContainer';

const PurchasedTogether = props => (
  <SvgContainer
    icon
    title="purchased_together"
    width={16}
    height={16}
    viewBoxHeight={64}
    viewBoxWidth={64}
    fill="red"
    {...props}
  >
    <path fill="#d02121" className="st0" d="M32.7 1.4c-17.3 0-31.3 14-31.3 31.3S15.4 64 32.7 64 64 50 64 32.7 50 1.4 32.7 1.4zm-7.3 42.3h-4.5c-5.5 0-10-4.5-10-10v-1.9c0-5.5 4.5-10 10-10h8c5.5 0 10 4.5 10 10V34c0 1.4-1.1 2.4-2.4 2.4s-2.4-1.1-2.4-2.4v-2.2c0-2.9-2.3-5.1-5.1-5.1h-8c-2.9 0-5.1 2.3-5.1 5.1v1.9c0 2.9 2.3 5.1 5.1 5.1h4.5c1.4 0 2.4 1.1 2.4 2.4s-1.1 2.5-2.5 2.5zm29.1-10c0 5.5-4.5 10-10 10H37c-5.5 0-10-4.5-10-10V32c0-1.4 1.1-2.4 2.4-2.4 1.4 0 2.4 1.1 2.4 2.4v1.7c0 2.9 2.3 5.1 5.1 5.1h7.5c2.9 0 5.1-2.3 5.1-5.1v-1.9c0-2.9-2.3-5.1-5.1-5.1h-3.9c-1.4 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4h3.9c5.5 0 10 4.5 10 10v1.8z" />
  </SvgContainer>
);

export default PurchasedTogether;
