/*
 * application.js
 *
 * This is the entry point for the current front end. It loads several React Components
 * which are only used on specific pages. The React Components should only be
 * rendered if their target elements are present.
 *
 * To reference this file, add <%= javascript_pack_tag 'application' %>
 * to your layout or view file.
 *
 */

import "core-js/stable";
import "regenerator-runtime/runtime";


import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';
import {
  createRoutesFromElements,
  Route,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import makeStore from 'config/store';
import ShoppingCart from "../vue/cart/cart";
import Tax from "../vue/cart/tax";
import User from "../vue/auth/user";

export const store = makeStore();

const navigationBarElement = document.getElementById('js-react-navigation-header');
if (navigationBarElement) {
  const NavigationHeader = loadable(() => import('containers/NavigationHeader'));

  const userSignedIn = JSON.parse(navigationBarElement.getAttribute('data-session'));
  const cartItems = JSON.parse(navigationBarElement.getAttribute('data-cart-items'));
  /*
  const htmlString = renderToString(<NavigationHeader signedIn={userSignedIn} activeCartItems={cartItems} />)
  console.log(htmlString)
  */
  ReactDOM.hydrate(
    <Provider store={store}>
      <NavigationHeader signedIn={userSignedIn} activeCartItems={cartItems} />
    </Provider>,
    navigationBarElement
  );
}

const navigationFooterElement = document.getElementById('js-react-navigation-footer');
if (navigationFooterElement) {
  const NavigationFooter = loadable(() => import('containers/NavigationFooter'));

  const userSignedIn = !!navigationBarElement && JSON.parse(navigationBarElement.getAttribute('data-session'));
  const timeUpdated = JSON.parse(navigationFooterElement.getAttribute('data-time-update'));
  const ensightenEnabled = JSON.parse(navigationFooterElement.getAttribute('data-ensighten-enabled'));
  /*
  const htmlString = renderToString(<NavigationFooter signedIn={userSignedIn} />)
  console.log(htmlString)
  */
  ReactDOM.hydrate(
    <Provider store={store}>
      <NavigationFooter signedIn={userSignedIn} updatedHours={timeUpdated} ensightenEnabled={ensightenEnabled} />
    </Provider>,
    navigationFooterElement
  );
}

const homePageElement = document.getElementById('js-react-home-page');
if (homePageElement) {
  const HomePage = loadable(() => import('containers/StaticPages/HomePage'));

  const occupationsAndSpecialties = JSON.parse(homePageElement.getAttribute('data-products-pages'));
  const announcements = JSON.parse(homePageElement.getAttribute('data-announcements'));
  const questionBankPages = JSON.parse(homePageElement.getAttribute('data-question-bank-pages'));
  const testimonials = JSON.parse(homePageElement.getAttribute('data-testimonials'));
  const blogs = JSON.parse(homePageElement.getAttribute('data-blogs'));
  const textContent = JSON.parse(homePageElement.getAttribute('data-text-content'));
  // Instructions to generate HTML content to replace index.html.erb
  // 1. Run `npm install html --global` on your terminal
  // 2. Uncomment lines below and add line `import { renderToString } from 'react-dom/server'`
  // 3. Reload the homepage and copy string from developer tools console
  // 4. Run `pbpaste | html | pbcopy` on your terminal
  // 5. Paste the clipboard content inside the top level div with id js-react-home-page on index.html.erb => It's already formatted properly for SEO
  // 6. Change the value on the ENV VAR HOME_CACHE_KEY on vault

  /*
  const htmlString = renderToString(<HomePage
          occupationsAndSpecialties={occupationsAndSpecialties}
          announcements={announcements}
          questionBankPages={questionBankPages}
          testimonials={testimonials}
          blogs={blogs}
        />)
  console.log(htmlString)
  */
  ReactDOM.hydrate(
    <Provider store={store}>
      <HomePage
        occupationsAndSpecialties={occupationsAndSpecialties}
        announcements={announcements}
        questionBankPages={questionBankPages}
        testimonials={testimonials}
        blogs={blogs}
        textContent={textContent}
      />
    </Provider>,
    homePageElement
  );
}


const hubspotElement = document.getElementById('inst-hubspot');
if (hubspotElement) {
  const HubspotForm = loadable(() => import('containers/StaticPages/HubspotForm'));

  const globalDataElement = document.getElementById('preload-global');
  const preloadedData = globalDataElement ? JSON.parse(globalDataElement.innerHTML) : {};
  ReactDOM.hydrate(
    <Provider store={store}>
      <HubspotForm preloadedData={preloadedData} />
    </Provider>,
    hubspotElement
  );
}

const showInstitutionBanks = (e) => {
  const qBankList = e.target.parentElement.parentElement.nextElementSibling;
  if (qBankList.classList.contains('hide')) {
    qBankList.classList.remove('hide');
  } else {
    qBankList.classList.add('hide');
  }
};

const institutionalBanksListElements = document.getElementsByClassName('available-banks-highlight');
if (institutionalBanksListElements) {
  Array.from(institutionalBanksListElements).forEach(function (el) {
    el.addEventListener('click', showInstitutionBanks, false);
  });
}


const sampleQuestionElement = document.getElementById('js-sample-question');
if (sampleQuestionElement) {
  const SampleQuestion = loadable(() => import('containers/SampleQuestion'));

  const sampleQuestionId = parseInt(sampleQuestionElement.getAttribute('data-question-id'));
  const questionDataElement = document.getElementById('preload-question');
  const preloadedData = questionDataElement ? JSON.parse(questionDataElement.innerHTML) : null;
  ReactDOM.hydrate(
    <Provider store={store}>
      <SampleQuestion questionId={sampleQuestionId} preloadedData={preloadedData} />
    </Provider>,
    sampleQuestionElement
  );
}

const cmeCoachElement = document.getElementById('js-cme-coach');
if (cmeCoachElement) {
  const CmeCoach = loadable(() => import('containers/CmeCoach'));

  const globalDataElement = document.getElementById('preload-global');
  const preloadedData = globalDataElement ? JSON.parse(globalDataElement.innerHTML) : {};
  ReactDOM.hydrate(
    <Provider store={store}>
      <CmeCoach preloadedData={preloadedData} />
    </Provider>,
    cmeCoachElement
  );
}


// const cmeProPlusElement = document.getElementById('cme-pro-plus-panel');
// if( cmeProPlusElement ) {
//   ReactDOM.render(
//     <Provider store={store}>
//       <GiftCardPDP specialties={sampleQuestionId} preloadedData={preloadedData} />
//     </Provider>,
//     sampleQuestionElement
//   );
// }


// For Question Previews from the Admin
// This will be loaded in an iframe but we have to be able to call this function
// from the parent page in order to pass in the preloaded JSON.
window.renderPreviewQuestion = function (preloadedData) {
  const previewQuestionEl = document.getElementById('js-preview-question');

  if (previewQuestionEl) {
    const SampleQuestion = loadable(() => import('containers/SampleQuestion'));
    const previewQuestionId = parseInt(previewQuestionEl.getAttribute('data-question-id'));

    // Start App
    ReactDOM.hydrate((
      <Provider store={store}>
        <SampleQuestion questionId={previewQuestionId} preloadedData={preloadedData} />
      </Provider>
    ), previewQuestionEl);
  }
};

const pdpPageElement = document.getElementById('js-react-pdp-page');
if (pdpPageElement) {
  const context = JSON.parse(pdpPageElement.getAttribute('data-transfer-object'));

  // see instructions in the homepage function to render the static html.
  const PdpPage = loadable(() => import('containers/ProductPages/PdpPage'));

  ReactDOM.hydrate(
    <Provider store={store}>
      <PdpPage context={context} />
    </Provider>,
    pdpPageElement
  );
}

const plpPageElement = document.getElementById('js-react-plp-page');

if (plpPageElement) {
  const context = JSON.parse(plpPageElement.getAttribute('data-transfer-object'));

  const PlpPage = loadable(() => import('containers/CmeProductListingPage/PlpPage'));

  ReactDOM.hydrate(
    <Provider store={store}>
      <PlpPage
        context={context}
      />
    </Provider>,
    plpPageElement
  );
}
