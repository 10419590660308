import q from 'q';

const Referral = function(){
  this.valid = false;
  this.failed = false;
  this.email = '';
}

Referral.prototype.validate = function(){
  
  var deferred = q.defer();
  
  $.ajax({
    url: '/referrals/validate?email=' + this.email,
    method: 'GET',
    dataType: 'json',
    success: function (data) {
      this.valid = data.valid;
      this.failed = !this.valid;
      if (this.valid == false) { this.email = '' }
      deferred.resolve(this);
    }.bind(this)
  });

  return deferred.promise;
}

Referral.prototype.reset = function(){
  this.valid = false;
  this.failed = false;
  this.email = '';
};

export default Referral;

