import Q from 'q';

class User {
  constructor(opts = {}) {
    // most of these properties are set via init
    this.signed_in = false;
    this.data = {};
    this.formValues = {
      first_name: null,
      last_name: null,
      email: null,
      email_confirmation: null,
      password: null,
      password_confirmation: null,
      user_type: null,
      sub_user_type: null,
      tos_accepted: null
    };

    this.userTypeList = [];
    this.subUserTypeMap = {};

    this.ready = false;
  }

  init() {
    console.log(`User.prototype.init`);
    return $.ajax({
      method: 'GET',
      url: '/users/auth_data',
      dataType: 'JSON',
      success: (resp) => {
        console.log('user#init status code 200: resp = { signed_in:, data: }');
        console.log('user#init success only means successful API response');
        console.log(`user#init resp.signed_in: [${resp.signed_in}]`);
        this.ready = true;
        return resp;
      },
      error: (err) => {
        console.log('user#init error');
        return err;
      }
    });
  }

  getCustData() {
    console.log(`User.prototype.getCustData: []`);
    return $.ajax({
      method: 'GET',
      url: '/users/cust_data',
      dataType: 'JSON',
      success: (resp) => {
        console.log('user#getCustData status code 200');
        if (resp.data) {
          this.data.stripe_customer = resp.data;
        }
        return;
      },
      error: (resp) => {
        return resp;
      }
    });
  }

  register(usr) {
    const deferred = Q.defer();

    $.ajax({
      url: '/users.json',
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({
        user: {
          first_name: usr.first_name,
          last_name: usr.last_name,
          email: usr.email,
          email_confirmation: usr.email_confirmation,
          password: usr.password,
          password_confirmation: usr.password_confirmation,
          tos_accepted: usr.tos_accepted,
          user_type: usr.user_type,
          sub_user_type: usr.sub_user_type
        }
      }),
      error: (resp) => {
        deferred.resolve(resp);
      },
      success: (resp) => {
        deferred.resolve(resp);
      }
    });

    return deferred.promise;
  }

  login(usr) {
    const deferred = Q.defer();

    $.ajax({
      url: '/users/sign_in.json',
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({
        email: usr.email,
        password: usr.password
      }),
      error: (resp) => {
        deferred.resolve(resp);
      },
      success: (resp) => {
        deferred.resolve(resp);
      }
    });

    return deferred.promise;
  }

  update(usr) {
    const deferred = Q.defer();

    $.ajax({
      url: `/users/${this.data.id}.json`,
      type: 'put',
      contentType: 'application/json',
      data: JSON.stringify({
        user: { location: usr.location, phone_number: usr.phone_number }
      }),
      error: (resp) => {
        deferred.resolve(resp);
      },
      success: (resp) => {
        deferred.resolve(resp);
      }
    });

    return deferred.promise;
  }

  cardIconName() {
    let cardType;
    if (this.data.stripe_customer) {
      cardType = this.data.stripe_customer.card_type;
    }

    if (cardType === 'Visa' || cardType === 'Discover' || cardType === 'MasterCard' || cardType === 'JCB') {
      return 'bv-cc-' + cardType.toLowerCase();
    } else if (cardType === 'American Express') {
      return 'bv-cc-amex';
    } else if (cardType === 'Diners Club') {
      return 'bv-cc-diners-club';
    } else {
      return 'bv-credit-card';
    }
  }

  nullifyStripe() {
    this.data.has_stripe_card = false;
    this.data.stripe_customer = null;
  }

  removeStripeCard() {
    this.nullifyStripe();
    return $.ajax({
      url: `/users/${this.data.id}/remove_stripe_card`,
      type: 'DELETE'
    }).then(
      (success) => {
        console.log(success.message);
      },
      (failure) => {
        console.log(`server error: ${failure}`);
      }
    );
  }
}

export default User;