import _ from 'lodash';

var Product = function(rawProduct){
  this.name = rawProduct.name;
  this.displayName = rawProduct.display_name;

  var daysToMonths = {31: "1 Month", 62: "2 Months", 93: "3 Months", 365: "1 Year", 10: "Trial", 185: "6 Months"}

  this.displayNameWithDuration = rawProduct.display_name;
  if (daysToMonths[rawProduct.duration] != null){
    this.displayNameWithDuration = `${rawProduct.display_name} - ${daysToMonths[rawProduct.duration]}`;
  }
  this.id = rawProduct.id;
  this.duration = rawProduct.duration;
  this.cmeDetails = rawProduct.cme_details;
  this.category = rawProduct.category;
  this.price = Math.floor(rawProduct.dollar_price);
  this.hasQuestionBankComponent = rawProduct.has_question_bank_component;
  this.hasCheckoutRequirement = rawProduct.has_checkout_requirement;
  this.discountable = rawProduct.discountable;
  this.isCmeProduct = rawProduct.is_cme_product;
  this.isTrialProduct = rawProduct.is_trial_product;
  this.questionBankIds = rawProduct.question_bank_ids || [];
  this.isStudentPurchaseOption = rawProduct.is_student_purchase;

  if (!rawProduct.is_student_purchase) {
    this.countSpecifications = rawProduct.count_specifications;
  }
  const cross_sell_data = rawProduct.updated_cross_sell_data || rawProduct.cross_sell_data;
  if ( cross_sell_data ) {
    this.tier1CrossSellDiscount = cross_sell_data.discount_tier_1;
    this.tier2CrossSellDiscount = cross_sell_data.discount_tier_2;
    this.tier1CrossSellBanks = cross_sell_data.tier_1_banks;
    this.tier2CrossSellBanks = cross_sell_data.tier_2_banks;


    const product = this;

    _.each([1, 2], function(tier){
      _.each(product['tier' + tier + 'CrossSellBanks'], function(bank){
        bank.showCrossSells = false;
        bank.discount = product['tier' + tier + 'CrossSellDiscount'];
        bank.selectedLength = null;
        bank.includesCme = false;
        bank.selectedProduct = selectedProduct.bind(bank);
        bank.adjustedCmePrice = adjustedCmePrice.bind(bank);
        bank.products = _.map(bank.products, function(subProduct){
          return new Product(subProduct);
        });

        if ( bank.cme_add_on ) {
          bank.cmeAddOn = new Product(bank.cme_add_on);
          bank.cmeDiscountAmount = bank.cme_discount_amount;
        };
      })
    });
  }
}

Product.prototype.priceWithPercentDiscount = function(percentOff) {
  return Math.floor(this.price * ((100 - percentOff) / 100));
};

Product.prototype.crossSellsForTier = function(tier){
  return _.uniqBy(this['tier' + tier + 'CrossSellBanks'], 'id');
};

Product.prototype.isEligibleForDelay = function(){
  return this.hasQuestionBankComponent && this.duration > 31 && !this.isStudentPurchaseOption;
};

var selectedProduct = function(){
  return _.find(this.products, function(product){
    return product.duration == this.selectedLength;
  }.bind(this));
};

var adjustedCmePrice = function(){
  return (this.selectedLength > 31) ? (this.cmeAddOn.price - this.cmeDiscountAmount) : this.cmeAddOn.price;
};

export default Product;
