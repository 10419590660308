import React from "react";
import SvgContainer from './SvgContainer';

const GreenCircleCheck = props => (
  <SvgContainer
    title="green_circle_check"
    width={16}
    height={16}
    viewBoxHeight={32}
    viewBoxWidth={32}
    {...props}
  >
    <circle fill="#8dc63f" className="st0" cx="16" cy="16" r="15.4" /><path className="st1" d="M24.5 8.5c-.3-.3-.7-.3-.9 0L13.8 18c-.3.3-.7.3-.9 0l-4.3-4.3c-.1-.1-.3-.2-.5-.2s-.3.1-.5.2l-1.9 1.7c-.1.1-.2.3-.2.5s.1.3.2.5l4.3 4.4.9.9 1.8 1.8c.3.3.7.3.9 0l12.5-12.3c.3-.3.3-.7 0-.9l-1.6-1.8z" />'
  </SvgContainer>
);

export default GreenCircleCheck;
